<template>
  <div class="content-body">
    <!-- modal -->
    <b-modal ref="my-modal" hide-footer title="Add a statement" size="lg">
      <validation-observer ref="new-statement-form" #default="{ invalid }">
        <b-form @submit.prevent="handleNewStatementSubmit">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Statement"
              rules="required"
            >
              <b-form-textarea
                id="textarea"
                v-model="newStatementModal.risk_statement"
                rows="3"
                max-rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            class="mt-50"
            :disabled="invalid"
          >
            <feather-icon icon="CheckIcon" class="mr-50" />
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-sidebar
      id="sidebar-custom-risk"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="580px"
      sidebar-class="sidebar-md"
    >
      <sidebar-template
        title="New Custom Risk"
        :closeAction="closeCustomRiskSidebar"
      >
        <template #content>
          <custom-risk
            :reloadParent="load"
            :closeAction="closeCustomRiskSidebar"
          />
        </template>
      </sidebar-template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="580px"
    >
      <sidebar-template title="Assessment Details" :closeAction="closeSidebar">
        <template #content>
          <AssetRisksAnalysisForm
            v-if="selectedRiskIndex !== null"
            :showOverlay="showOverlay"
            :risk="asset.asset_risks[selectedRiskIndex]"
            :closeSidebar="closeSidebar"
            :incrementThreatValue="incrementThreatValue"
            :decrementThreatValue="decrementThreatValue"
            :incrementVulnerabilityValue="incrementVulnerabilityValue"
            :decrementVulnerabilityValue="decrementVulnerabilityValue"
            :incrementResidualThreatValue="incrementResidualThreatValue"
            :decrementResidualThreatValue="decrementResidualThreatValue"
            :incrementResidualVulnerabilityValue="
              incrementResidualVulnerabilityValue
            "
            :decrementResidualVulnerabilityValue="
              decrementResidualVulnerabilityValue
            "
            :handleLikelihoodValueChange="handleLikelihoodValueChange"
            :handleResidualLikelihoodValueChange="
              handleResidualLikelihoodValueChange
            "
            :handleControlsValueChange="handleControlsValueChange"
          />
        </template>
      </sidebar-template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-info"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
    >
      <asset-risk-info :closeSidebar="closeInfoSidebar" :data="selectedRisk" />
    </b-sidebar>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div
            class="col-xl-8 col-12 d-flex flex-column justify-content-between"
          >
            <div style="width: 85%">
              <h3 class="font-weight-bolder">
                {{ asset ? asset.asset_name : "" }}
              </h3>
              <b-row v-if="asset">
                <template v-for="tag in asset.tags">
                  <span :key="tag._id" class="card-text ml-1">
                    <b-badge variant="light-primary">{{ tag.title }}</b-badge>
                  </span>
                </template>
              </b-row>
            </div>
            <div class="d-flex flex-wrap mt-2">
              <b-button
                variant="outline-info"
                size="sm"
                class="mr-1"
                @click="$router.push({ name: 'asset-edit' })"
              >
                <span>
                  <feather-icon icon="EditIcon" class="mr-25" />
                  Edit
                </span>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="showConfirmDeleteMsgBox()"
              >
                <span>
                  <feather-icon icon="TrashIcon" class="mr-25" />
                  Delete
                </span>
              </b-button>
            </div>
          </div>
          <div class="col-xl-4 col-12">
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon class="mr-75" icon="UserIcon" />
                  <span class="font-weight-bold">Owner</span>
                </th>

                <td class="pb-50">
                  <template v-if="assetOwner && assetOwner != null">
                    {{ assetOwner.firstname ? assetOwner.firstname : "" }}
                    {{ assetOwner.lastname ? assetOwner.lastname : "" }}
                  </template>

                  <b-badge v-else variant="danger">Deleted User</b-badge>
                </td>
              </tr>

              <!-- <tr>
                <th class="pb-50">
                  <feather-icon class="mr-75" icon="StarIcon" />
                  <span class="font-weight-bold">Role</span>
                </th>
                <td class="pb-50">Admin</td>
              </tr> -->

              <tr>
                <th class="pb-50">
                  <feather-icon class="mr-75" icon="FlagIcon" />
                  <span class="font-weight-bold">Location</span>
                </th>
                <td class="pb-50">{{ assetLocation }}</td>
              </tr>

              <!-- <tr>
                <th class="pb-50">
                  <feather-icon class="mr-75" icon="CheckIcon" />
                  <span class="font-weight-bold">Status</span>
                </th>
                <td class="pb-50">Unknown</td>
              </tr> -->

              <tr>
                <th class="pb-50">
                  <feather-icon class="mr-75" icon="GitBranchIcon" />
                  <span class="font-weight-bold">Asset Classification</span>
                </th>
                <td class="pb-50">
                  {{ assetClassification ? assetClassification.title : "" }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon class="mr-75" icon="CircleIcon" />
                  <span class="font-weight-bold">Asset Category</span>
                </th>
                <td class="pb-50">
                  <b-badge variant="primary" v-if="asset">{{
                    asset.category.name
                  }}</b-badge>
                </td>
              </tr>

              <tr>
                <th class="pb-50">
                  <feather-icon class="mr-75" icon="DollarSignIcon" />
                  <span class="font-weight-bold">Asset Value</span>
                </th>
                <td class="pb-50">
                  <b-badge
                    v-if="asset"
                    v-bind:variant="getVariant(parseInt(asset.asset_value))"
                    >{{
                      getScoreValueFromNumber(parseInt(asset.asset_value))
                    }}</b-badge
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-sm-6"
          >
            <h3 class="mb-25 font-weight-bolder">Risk Assessments</h3>
            <p class="mb-50">Manage risks of this asset.</p>
          </div>
          <div class="col-sm-6">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            >
              <b-button
                @click="closeCustomRiskSidebar"
                size="sm"
                variant="success"
                class="mr-1"
                ><feather-icon icon="PlusIcon" class="mr-50" />Add Custom
                Risk</b-button
              >
              <b-button
                size="sm"
                @click="showRepopulateRisksConfirmMsgBox"
                variant="warning"
                ><feather-icon icon="RefreshCcwIcon" class="mr-50" />Repopulate
                Risks</b-button
              >
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="m-2">
        <div class="row">
          <h2 class="ml-2 text-dark font-weight-bold">Risk Assessment</h2>
        </div>
        <div class="row justify-content-start my-2">
          <b-button class="ml-2" @click="handleCreateCustomRiskClick" variant="success"
            ><feather-icon icon="PlusIcon" class="mr-50" />Create Custom Risk</b-button
          >
          <b-button
            class="ml-2"
            @click="showRepopulateRisksConfirmMsgBox"
            variant="warning"
            ><feather-icon icon="RefreshCcwIcon" class="mr-50" />Repopulate
            Risks</b-button
          >
        </div>
      </div> -->
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div
          class="table-wrapper-scroll-y my-custom-scrollbar"
          style="width: 100%"
        >
          <table role="table" class="table table-condensed">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col" class="header"></th>
                <th role="columnheader" scope="col" class="header">
                  # Risk Name
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Statement
                </th>
                <!-- <th role="columnheader" scope="col" class="header">Threat</th>
                <th role="columnheader" scope="col" class="header">
                  Vulnerability
                </th> -->
                <th role="columnheader" scope="col" class="text-center header">
                  Impact Value
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Risk Value
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Residual Impact Value
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Residual Risk
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template
                v-if="
                  asset &&
                  asset.asset_risks &&
                  asset.asset_risks != null &&
                  asset.asset_risks.length > 0
                "
              >
                <template v-for="(risk, i) in asset.asset_risks">
                  <tr
                    @click="handleRowClick(risk)"
                    class="b-table-has-details cursor-pointer"
                    :class="{
                      'bg-light-secondary': risk.status === 1 ? false : true,
                      'expanded-row':
                        expandedList.includes(risk._id) && risk.status === 1,
                    }"
                    role="row"
                    :key="risk._id"
                  >
                    <td>{{ i + 1 }}</td>
                    <td aria-colindex="2" role="cell" style="max-width: 30vw">
                      <div
                        class="d-flex align-items-center justify-content-start w-100"
                      >
                        <!-- <span class="mr-75 font-weight-bolder"
                          >{{ i + 1 }}.</span
                        > -->
                        <p class="text-left font-weight-bol mb-0">
                          {{ risk.risk.name }}
                        </p>
                      </div>
                    </td>

                    <td aria-colindex="3" role="cell" style="max-width: 12vw">
                      <div
                        v-if="!risk.risk_statement"
                        class="d-flex align-items-center justify-content-center w-100"
                      >
                        <b-button
                          size="sm"
                          variant="outline-primary"
                          @click.stop="
                            handleNewStatementClick($route.params.id, risk._id)
                          "
                          ><span>
                            <feather-icon icon="PlusIcon" class="mr-25" />Add
                          </span>
                        </b-button>
                      </div>
                      <p
                        v-else
                        class="font-weight-bold text-left mb-0 text-truncate"
                        v-b-tooltip.hover.secondary.top
                        :title="risk.risk_statement"
                      >
                        {{ risk.risk_statement }}
                      </p>
                    </td>

                    <!-- <td aria-colindex="4" role="cell" style="padding-right: 0; width: 300px">
                      <div
                        style="width: 300px"
                        class="d-flex justify-content-start"
                      >
                        <p
                          class="font-weight-normal text-left mb-0"
                          v-if="risk.threat"
                        >
                          {{ risk.threat.name }}
                        </p>
                      </div>
                    </td> -->

                    <!-- <td aria-colindex="6" role="cell" style="padding-right: 0; width: 300px">
                      <div
                        style="width: 300px"
                        class="d-flex justify-content-start"
                      >
                        <p
                          class="font-weight-normal mb-0"
                          v-if="risk.vulnerability"
                        >
                          {{ risk.vulnerability.name }}
                        </p>
                      </div>
                    </td> -->

                    <td aria-colindex="8" role="cell">
                      <div class="d-flex justify-content-center">
                        <!-- <progress-bar
                          :value="risk.impact_value"
                          :min="1"
                          :max="100"
                        /> -->
                        <progress-bar-v-2
                          :value="risk.impact_value"
                          :min="1"
                          :max="100"
                        />
                      </div>
                    </td>

                    <!-- <td aria-colindex="9" role="cell">
                      <b-form-select
                        style="width: 170px"
                        class="font-weight-bold"
                        size="sm"
                        v-model="risk.likelihood_value"
                        v-on:change="handleLikelihoodValueChange(risk._id)"
                        :options="likelihoodOptions"
                        :disabled="risk.status !== 1"
                      ></b-form-select>
                    </td> -->

                    <td aria-colindex="10" role="cell">
                      <div class="d-flex justify-content-center">
                        <!-- <progress-bar :value="risk.risk_value" :min="1" :max="100" /> -->
                        <progress-bar-v-2
                          :value="risk.risk_value"
                          :min="1"
                          :max="100"
                        />
                      </div>
                    </td>

                    <!-- <td aria-colindex="13" role="cell">
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <score-input
                          :id="risk._id"
                          :value="Number(risk.residual_threat_value)"
                          :increment="incrementResidualThreatValue"
                          :decrement="decrementResidualThreatValue"
                        />
                      </div>
                    </td> -->

                    <!-- <td aria-colindex="14" role="cell">
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <score-input
                          :id="risk._id"
                          :value="Number(risk.residual_vulnerability_value)"
                          :increment="incrementResidualVulnerabilityValue"
                          :decrement="decrementResidualVulnerabilityValue"
                        />
                      </div>
                    </td> -->

                    <td aria-colindex="11" role="cell">
                      <div class="d-flex justify-content-center">
                        <progress-bar-v-2
                          :value="risk.residual_impact_value"
                          :min="1"
                          :max="100"
                        />
                        <!-- <progress-bar
                          :value="risk.residual_impact_value"
                          :min="1"
                          :max="100"
                        /> -->
                      </div>
                    </td>

                    <!-- <td aria-colindex="12" role="cell">
                      <b-form-select
                        class="font-weight-bold"
                        style="width: 170px"
                        size="sm"
                        v-model="risk.residual_likelihood_value"
                        v-on:change="
                          handleResidualLikelihoodValueChange(risk._id)
                        "
                        :options="likelihoodOptions"
                        :disabled="risk.status !== 1"
                      ></b-form-select>
                    </td> -->

                    <td aria-colindex="15" role="cell">
                      <div class="d-flex justify-content-center">
                        <progress-bar-v-2
                          :value="risk.residual_risk_value"
                          :min="1"
                          :max="100"
                        />
                        <!-- <progress-bar
                          :value="risk.residual_risk_value"
                          :min="1"
                          :max="100"
                        /> -->
                      </div>
                    </td>

                    <td aria-colindex="16" role="cell">
                      <div
                        class="d-flex justify-content-between align-items-center w-100"
                      >
                        <b-button
                          v-if="risk.status === 1"
                          variant="danger darken-2"
                          class="btn-icon rounded-circle mx-auto mr-50"
                          v-b-tooltip.hover.secondary.top
                          title="Set As Not Applicable"
                          @click.stop="showRemoveRiskConfirmMsgBox(risk._id)"
                          size="sm"
                        >
                          <feather-icon
                            size="16"
                            class="text-light"
                            icon="MinusIcon"
                          />
                        </b-button>

                        <b-button
                          size="sm"
                          v-if="risk.status === 2"
                          variant="success"
                          class="btn-icon rounded-circle mx-auto"
                          v-b-tooltip.hover.secondary.top
                          title="Set As Applicable"
                          @click.stop="
                            addRiskToAsset($route.params.id, risk._id)
                          "
                        >
                          <feather-icon size="16" icon="PlusIcon" />
                        </b-button>

                        <b-button
                          size="sm"
                          v-if="risk.status === 1"
                          variant="secondary darken-2"
                          class="btn-icon rounded-circle mx-auto"
                          v-b-tooltip.hover.secondary.top
                          title="Replicate Controls"
                          @click.stop="
                            showConfirmReplicateMsgBox({
                              riskId: risk._id,
                              riskName: risk.risk.name,
                            })
                          "
                        >
                          <feather-icon
                            size="16"
                            class="text-light"
                            icon="CopyIcon"
                          />
                        </b-button>
                      </div>
                    </td>
                  </tr>

                  <tr
                    :key="risk._id + 2"
                    aria-hidden="true"
                    role="presentation"
                    class="d-none"
                  ></tr>

                  <tr
                    :key="risk._id + 1"
                    tabindex="-1"
                    role="row"
                    class="b-table-details"
                    :class="{
                      'expanded-row-section2':
                        expandedList.includes(risk._id) && risk.status === 1,
                      'd-none':
                        !expandedList.includes(risk._id) || risk.status !== 1,
                    }"
                  >
                    <td role="cell" colspan="12">
                      <b-collapse
                        :id="'collapse' + risk._id"
                        style="width: 100%"
                      >
                        <div class="card">
                          <div class="card-body">
                            <table role="table" class="table b-table">
                              <thead>
                                <tr>
                                  <th
                                    class="bg-secondary bg-darken-2 text-light"
                                  >
                                    Control
                                  </th>
                                  <th
                                    class="bg-secondary bg-darken-2 text-light"
                                  >
                                    Number
                                  </th>
                                  <th
                                    class="bg-secondary bg-darken-2 text-light"
                                  >
                                    Description
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template
                                  v-for="(
                                    control, ci
                                  ) in risk.asset_risk_controls"
                                >
                                  <tr :key="control.control_id">
                                    <td>
                                      <b-form-checkbox
                                        :disabled="risk.status !== 1"
                                        class="my-1"
                                        v-on:change="
                                          handleControlsValueChange(
                                            $event,
                                            risk._id,
                                            control.control_id
                                          )
                                        "
                                        v-model="
                                          asset.asset_risks[i]
                                            .asset_risk_controls[ci].exists
                                        "
                                        ><span
                                          class="text-primary font-weight-bold"
                                          >{{ control.control.name }}</span
                                        ></b-form-checkbox
                                      >
                                    </td>
                                    <td>
                                      <span
                                        class="text-dark font-weight-bold"
                                        >{{ control.control.control_no }}</span
                                      >
                                    </td>
                                    <td>
                                      <span class="text-dark font-italic">{{
                                        control.control.description
                                      }}</span>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </b-collapse>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <empty-table-section title="No Risks Found">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      It seems like there are no risks at the moment.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BCardGroup,
  BButton,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BBadge,
  BSidebar,
  VBToggle,
  BFormSelect,
  BOverlay,
  BSpinner,
  BFormCheckbox,
  VBModal,
  VBTooltip,
  BCollapse,
  BAvatar,
  BModal,
  BFormTextarea,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import ScoreInput from "@/components/ScoreInput.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AssetMixins from "../../mixins/AssetMixins";
import RiskMixins from "@/mixins/RiskMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import ControlMixins from "@/mixins/ControlMixins";
import ProgressPercent from "../../components/ProgressPercent.vue";
import ProgressBar from "../../components/ProgressBar.vue";

import AssetRisksAnalysisForm from "./components/AssetRisksAnalysisForm.vue";
import AssetRiskInfo from "./components/AssetRiskInfo.vue";
import ProgressBarV2 from "@/components/ProgressBarV2.vue";
import SidebarTemplate from "@/components/SidebarTemplate.vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import BackButton from "@/components/BackButton.vue";
import CustomRisk from "../RiskRegister/CustomRisk.vue";
export default {
  name: "AssetDetailsPage",
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardGroup,
    BButton,
    Ripple,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    ToastificationContent,
    BSidebar,
    BFormSelect,
    BOverlay,
    BSpinner,
    BFormCheckbox,
    BCollapse,
    BAvatar,
    ScoreInput,
    BModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BForm,
    ProgressPercent,
    AssetRisksAnalysisForm,
    ProgressBar,
    AssetRiskInfo,
    ProgressBarV2,
    SidebarTemplate,
    EmptyTableSection,
    BFormGroup,
    BackButton,
    CustomRisk,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      asset: null,
      assetLocation: null,
      assetOwner: null,
      assetType: null,
      assetClassification: null,
      newStatementModal: {
        risk_statement: null,
        assetId: null,
        assetRiskId: null,
      },
      impactOptions: [{ value: null, text: "Select a Impact Option" }],
      residualRiskOptions: [
        { value: null, text: "Select a value.." },
        { value: 1, text: "Very Low" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],
      likelihoodOptions: [
        { value: null, text: "Select a value.." },
        { value: 1, text: "Once 5 years" },
        { value: 2, text: "Once 2 years" },
        { value: 3, text: "Once a year" },
        { value: 4, text: "Once in Six Months" },
        { value: 5, text: "Once Month" },
      ],
      riskScore: 1,
      showOverlay: false,
      expandedList: [],
      selectedRisk: null,
      selectedRiskIndex: null,
    };
  },
  mounted() {
    this.load();
  },
  mixins: [AssetMixins, RiskMixins, ControlMixins, ResponseMixins],
  computed: {
    mapScoreToTextValue() {
      return (val) => {
        if (val && val > 1 && val <= 5) {
          if (val == 2) {
            return "Low";
          }
          if (val == 3) {
            return "Medium";
          }
          if (val == 4) {
            return "High";
          }
          if (val == 5) {
            return "Critical";
          }
        } else {
          return "Very Low";
        }
      };
    },
  },
  methods: {
    load() {
      this.getAssetDetailsAndSetData();
    },
    closeCustomRiskSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-custom-risk");
      this.selectedRiskIndex = null;
    },

    openCustomRiskSidebar: function (risk) {
      this.$root.$emit("bv::toggle::collapse", "sidebar-custom-risk");
    },

    handleInfoViewClick(risk) {
      this.selectedRisk = risk;
      this.openInfoSidebar();
    },

    closeInfoSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-info");
      this.selectedRisk = null;
    },
    openInfoSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-info");
    },

    nFormatter(num, digits) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return num >= item.value;
        });
      return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0";
    },
    mapRangeValues(value, oldRange, newRange) {
      var newValue =
        ((value - oldRange[0]) * (newRange[1] - newRange[0])) /
          (oldRange[1] - oldRange[0]) +
        newRange[0];
      console.log(newValue);
      return Math.min(Math.max(newValue, newRange[0]), newRange[1]);
    },
    getVariant(status) {
      switch (status) {
        case 1:
          return "success";
        case 2:
          return "info";
        case 3:
          return "primary";
        case 4:
          return "warning";
        case 5:
          return "danger";
        default:
          return "success";
      }
    },
    getScoreValueFromNumber(num) {
      switch (num) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        case 4:
          return "Very High";
        default:
          return "Very Low";
      }
    },
    getBorderVariant(status) {
      switch (status) {
        case 1:
          return "border border-info";
        case 2:
          return "border border-success";
        case 3:
          return "border border-warning";
        case 4:
          return "border border-danger";
        case 5:
          return "border border-primary";
        default:
          return "border border-info";
      }
    },
    getTextVariant(status) {
      switch (status) {
        case 1:
          return "text-info";
        case 2:
          return "text-success";
        case 3:
          return "text-warning";
        case 4:
          return "text-danger";
        case 5:
          return "text-primary";
        default:
          return "text-info";
      }
    },
    showConfirmDeleteMsgBox() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this Asset.", {
          title: "Delete Asset",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteAssetWithId(this.$route.params.id);
            this.$router.push({ name: "assets" });
          }
        });
    },
    toggleTableRow(id) {
      const index = this.expandedList.indexOf(id);
      if (index > -1) {
        this.expandedList.splice(index, 1);
      } else {
        this.expandedList.push(id);
      }
    },
    showConfirmReplicateMsgBox(obj) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          `Please Confirm that you want to Replicate controls of ${obj.riskName} to all risks that match this Asset Category.`,
          {
            title: "Replicate Controls",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.handleReplicateControls(obj);
          }
        });
    },
    handleReplicateControls(obj) {
      obj["assetId"] = this.$route.params.id;
      this.replicateControls(obj)
        .then((res) => {
          console.log(res);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Controls have been replicated",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          }
          console.log(err);
        });
    },
    getAssetDetailsAndSetData() {
      this.getAssetDetails(this.$route.params.id)
        .then((res) => {
          console.log("Asset Details", res);
          const newAsset = res.data.data;
          this.asset = newAsset;
          this.assetLocation =
            newAsset.asset_location && newAsset.asset_location != null
              ? newAsset.asset_location[0]
              : "";
          if (newAsset.owner && newAsset.owner != null) {
            this.assetOwner = newAsset.owner;
          }
          if (
            newAsset.asset_category_id &&
            newAsset.asset_category_id != null
          ) {
            this.getAssetTypeAndSetData(newAsset.asset_category_id);
          }
          if (
            newAsset.asset_classification_id &&
            newAsset.asset_classification_id != null
          ) {
            this.getAssetClassification(newAsset.asset_classification_id);
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.handleError(err);
        });
    },
    getAssetTypeAndSetData(id) {
      this.getAssetType(id)
        .then((res) => {
          this.assetType = res.data.data.asset;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getAssetClassification(id) {
      const options = {
        method: "GET",
        url: `${process.env.VUE_APP_BASEURL}/asset-classifications/` + id,
      };
      this.$http(options)
        .then((res) => {
          this.assetClassification = res.data.data;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    deleteAssetWithId(id) {
      const options = {
        method: "DELETE",
        url: `${process.env.VUE_APP_BASEURL}/assets/` + id,
      };
      this.$http(options)
        .then((res) => {
          console.log("del res", res);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Asset Deleted Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "The Asset has not been Deleted",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    showRemoveRiskConfirmMsgBox(riskId) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to set this Risk as Not Applicable.",
          {
            title: "Remove Risk",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.removeRiskFromAsset(this.$route.params.id, riskId);
          }
        });
    },
    showRepopulateRisksConfirmMsgBox() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Repopulate these Risks.",
          {
            title: "Repopulate Risks",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.handleRepopulateRiskClick();
          }
        });
    },
    removeRiskFromAsset(assetId, riskId) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}/remove`,
      };
      this.$http(options)
        .then((res) => {
          const newData = res.data.data;
          const riskIndex = this.asset.asset_risks.findIndex(
            (risk) => risk._id == newData._id
          );
          this.asset.asset_risks[riskIndex].status = newData.status;
        })
        .catch((err) => {
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          }
          console.log(err);
        });
    },
    addRiskToAsset(assetId, riskId) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}/add`,
      };
      this.$http(options)
        .then((res) => {
          const newData = res.data.data;
          const riskIndex = this.asset.asset_risks.findIndex(
            (risk) => risk._id == newData._id
          );
          this.asset.asset_risks[riskIndex].status = newData.status;
        })
        .catch((err) => {
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          }
          console.log(err);
        });
    },
    getLikelihoodOptions() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/asset-risk/likelyhoods`,
      };
      this.$http(options)
        .then((res) => {
          let likelihood = res.data.data;
          for (let i = 0; i < likelihood.length; i++) {
            this.likelihoodOptions.push({
              value: likelihood[i].VALUE,
              text: likelihood[i].TITLE,
            });
          }
        })
        .catch((err) => {
          this.handleError(err);
          // if (err.response) {
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: err.response.data.message,
          //       icon: "AlertOctagonIcon",
          //       variant: "danger",
          //     },
          //   });
          // }
          console.log(err);
        });
    },
    getImpactOptions() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/asset-risk/impacts`,
      };
      this.$http(options)
        .then((res) => {
          let impacts = res.data.data;
          for (let i = 0; i < impacts.length; i++) {
            this.impactOptions.push({
              value: impacts[i].VALUE,
              text: impacts[i].TITLE,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          }
          console.log(err);
        });
    },
    handleImpactValueChange(newValue, riskId, likelihoodValue) {
      this.showOverlay = true;
      this.updateRiskImpactAndLikelihoodRelation(
        newValue,
        likelihoodValue,
        riskId,
        this.$route.params.id
      )
        .then((res) => {
          const newData = res.data.data;
          let riskIndex = this.asset.asset_risks.findIndex(
            (risk) => risk.risk_id === newData.risk_id
          );
          this.asset.asset_risks[riskIndex].impact_value = newData.impact_value;
          this.asset.asset_risks[riskIndex].likelihood_value =
            newData.likelihood_value;
          this.asset.asset_risks[riskIndex].risk_value = newData.risk_value;
          this.showOverlay = false;
        })
        .catch((err) => {
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          }
          this.showOverlay = false;
          console.log(err);
        });
    },
    // handleLikelihoodValueChange(newValue, riskId, impactValue) {
    //   this.showOverlay = true;
    //   this.updateRiskImpactAndLikelihoodRelation(
    //     impactValue,
    //     newValue,
    //     riskId,
    //     this.$route.params.id
    //   )
    //     .then((res) => {
    //       const newData = res.data.data;
    //       let riskIndex = this.asset.asset_risks.findIndex(
    //         (risk) => risk.risk_id === newData.risk_id
    //       );
    //       this.asset.asset_risks[riskIndex].impact_value = newData.impact_value;
    //       this.asset.asset_risks[riskIndex].likelihood_value =
    //         newData.likelihood_value;
    //       this.asset.asset_risks[riskIndex].risk_value = newData.risk_value;
    //       this.showOverlay = false;
    //     })
    //     .catch((err) => {
    //       if (err.response) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: err.response.data.message,
    //             icon: "AlertOctagonIcon",
    //             variant: "danger",
    //           },
    //         });
    //       }
    //       this.showOverlay = false;
    //       console.log(err);
    //     });
    // },
    handleResidualRiskScoreChange(
      newValue,
      riskId,
      impactValue,
      likelihoodValue
    ) {
      this.showOverlay = true;
      this.updateResidualRiskScore(
        impactValue,
        likelihoodValue,
        newValue,
        riskId,
        this.$route.params.id
      )
        .then((res) => {
          const newData = res.data.data;
          let riskIndex = this.asset.asset_risks.findIndex(
            (risk) => risk.risk_id === newData.risk_id
          );
          this.asset.asset_risks[riskIndex].impact_value = newData.impact_value;
          this.asset.asset_risks[riskIndex].likelihood_value =
            newData.likelihood_value;
          this.asset.asset_risks[riskIndex].risk_value = newData.risk_value;
          this.asset.asset_risks[riskIndex].residual_risk_value =
            newData.residual_risk_value;
          this.showOverlay = false;
        })
        .catch((err) => {
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          }
          this.showOverlay = false;
          console.log(err);
        });
    },
    handleCreateCustomRiskClick() {
      this.$router.push({ name: "create-custom-risk" });
    },
    handleControlsValueChange(newValue, riskId, controlId) {
      this.showOverlay = true;
      if (newValue === true) {
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/assets/${this.$route.params.id}/risks/${riskId}/controls/${controlId}/add`,
        };
        this.$http(options)
          .then((res) => {
            this.showOverlay = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Control has been modified",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((err) => {
            this.showOverlay = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Sorry an Error occured.",
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
            console.log(err);
          });
      } else {
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/assets/${this.$route.params.id}/risks/${riskId}/controls/${controlId}/remove`,
        };
        this.$http(options)
          .then((res) => {
            this.showOverlay = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Control has been modified",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((err) => {
            this.showOverlay = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Sorry an Error occured.",
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
            console.log(err);
          });
      }
    },
    handleRepopulateRiskClick() {
      this.repopulateRisks(this.$route.params.id)
        .then((res) => {
          this.handleResponse(res, "Risks Successfully Repopulated");
          this.getAssetDetailsAndSetData();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    handleNewStatementClick(assetId, assetRiskId) {
      this.newStatementModal.assetId = assetId;
      this.newStatementModal.assetRiskId = assetRiskId;
      this.showModal();
    },

    updateAssetRiskState(res) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === res.data.data._id
      );
      this.asset.asset_risks[riskIndex] = res.data.data;
    },

    handleNewStatementSubmit() {
      this.hideModal();
      this.showOverlay = true;
      this.updateAssetRisk(
        this.newStatementModal.assetId,
        this.newStatementModal.assetRiskId,
        {
          risk_statement: this.newStatementModal.risk_statement,
        }
      )
        .then((res) => {
          this.newStatementModal.assetId = null;
          this.newStatementModal.assetRiskId = null;
          this.newStatementModal.risk_statement = null;
          this.updateAssetRiskState(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
          // if (err.response) {
          //   this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: err.response.data.message,
          //       icon: "AlertOctagonIcon",
          //       variant: "danger",
          //     },
          //   });
          // }
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      this.selectedRiskIndex = null;
    },
    openSidebar: function (risk) {
      let riskIndex = this.asset.asset_risks.findIndex(
        (r) => r._id === risk._id
      );
      this.selectedRiskIndex = riskIndex;
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },

    handleRowClick(risk) {
      if (risk.status === 1) {
        this.openSidebar(risk);
      }
    },

    // ------------ Risk Update Functions ---------

    incrementThreatValue(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      if (riskIndex !== -1) {
        let riskItem = this.asset.asset_risks[riskIndex];
        let threatValue = Number(riskItem.threat_value);
        let newValue = threatValue + 1;
        if (threatValue < 5) {
          this.showOverlay = true;
          this.updateAssetRisk(this.$route.params.id, id, {
            threat_value: newValue,
          })
            .then((res) => {
              this.updateAssetRiskState(res);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.showOverlay = false;
            });
        }
      }
    },
    decrementThreatValue(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      if (riskIndex !== -1) {
        let riskItem = this.asset.asset_risks[riskIndex];
        let threatValue = Number(riskItem.threat_value);
        let newValue = threatValue - 1;
        if (threatValue > 1) {
          this.showOverlay = true;
          this.updateAssetRisk(this.$route.params.id, id, {
            threat_value: newValue,
          })
            .then((res) => {
              this.updateAssetRiskState(res);
              this.showOverlay = false;
            })
            .catch((err) => {
              console.log(err);
              this.showOverlay = false;
            })
            .finally(() => {
              this.showOverlay = false;
            });
        }
      }
    },

    incrementVulnerabilityValue(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      if (riskIndex !== -1) {
        let riskItem = this.asset.asset_risks[riskIndex];
        let vulValue = Number(riskItem.vulnerability_value);
        let newValue = vulValue + 1;
        if (vulValue < 5) {
          this.showOverlay = true;
          this.updateAssetRisk(this.$route.params.id, id, {
            vulnerability_value: newValue,
          })
            .then((res) => {
              this.updateAssetRiskState(res);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.showOverlay = false;
            });
        }
      }
    },
    decrementVulnerabilityValue(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      if (riskIndex !== -1) {
        let riskItem = this.asset.asset_risks[riskIndex];
        let vulValue = Number(riskItem.vulnerability_value);
        let newValue = vulValue - 1;
        if (vulValue > 1) {
          this.showOverlay = true;
          this.updateAssetRisk(this.$route.params.id, id, {
            vulnerability_value: newValue,
          })
            .then((res) => {
              this.updateAssetRiskState(res);
              this.showOverlay = false;
            })
            .catch((err) => {
              console.log(err);
              this.showOverlay = false;
            })
            .finally(() => {
              this.showOverlay = false;
            });
        }
      }
    },

    incrementResidualVulnerabilityValue(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      if (riskIndex !== -1) {
        let riskItem = this.asset.asset_risks[riskIndex];
        let vulValue = Number(riskItem.residual_vulnerability_value);
        let newValue = vulValue + 1;
        if (vulValue < 5) {
          this.showOverlay = true;
          this.updateAssetRisk(this.$route.params.id, id, {
            residual_vulnerability_value: newValue,
          })
            .then((res) => {
              this.updateAssetRiskState(res);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.showOverlay = false;
            });
        }
      }
    },
    decrementResidualVulnerabilityValue(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      if (riskIndex !== -1) {
        let riskItem = this.asset.asset_risks[riskIndex];
        let vulValue = Number(riskItem.residual_vulnerability_value);
        let newValue = vulValue - 1;
        if (vulValue > 1) {
          this.showOverlay = true;
          this.updateAssetRisk(this.$route.params.id, id, {
            residual_vulnerability_value: newValue,
          })
            .then((res) => {
              this.updateAssetRiskState(res);
              this.showOverlay = false;
            })
            .catch((err) => {
              console.log(err);
              this.showOverlay = false;
            })
            .finally(() => {
              this.showOverlay = false;
            });
        }
      }
    },

    incrementResidualThreatValue(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      if (riskIndex !== -1) {
        let riskItem = this.asset.asset_risks[riskIndex];
        let threatValue = Number(riskItem.residual_threat_value);
        let newValue = threatValue + 1;
        if (threatValue < 5) {
          this.showOverlay = true;
          this.updateAssetRisk(this.$route.params.id, id, {
            residual_threat_value: newValue,
          })
            .then((res) => {
              this.updateAssetRiskState(res);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.showOverlay = false;
            });
        }
      }
    },
    decrementResidualThreatValue(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      if (riskIndex !== -1) {
        let riskItem = this.asset.asset_risks[riskIndex];
        let threatValue = Number(riskItem.residual_threat_value);
        let newValue = threatValue - 1;
        if (threatValue > 1) {
          this.showOverlay = true;
          this.updateAssetRisk(this.$route.params.id, id, {
            residual_threat_value: newValue,
          })
            .then((res) => {
              this.updateAssetRiskState(res);
              this.showOverlay = false;
            })
            .catch((err) => {
              console.log(err);
              this.showOverlay = false;
            })
            .finally(() => {
              this.showOverlay = false;
            });
        }
      }
    },

    handleResidualLikelihoodValueChange(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      let riskItem = this.asset.asset_risks[riskIndex];
      this.showOverlay = true;
      this.updateAssetRisk(this.$route.params.id, id, {
        residual_likelihood_value: riskItem.residual_likelihood_value,
      })
        .then((res) => {
          this.updateAssetRiskState(res);
          this.showOverlay = false;
        })
        .catch((err) => {
          console.log(err);
          this.showOverlay = false;
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    handleLikelihoodValueChange(id) {
      const riskIndex = this.asset.asset_risks.findIndex(
        (risk) => risk._id === id
      );
      let riskItem = this.asset.asset_risks[riskIndex];
      this.showOverlay = true;
      this.updateAssetRisk(this.$route.params.id, id, {
        likelihood_value: riskItem.likelihood_value,
      })
        .then((res) => {
          this.updateAssetRiskState(res);
          this.showOverlay = false;
        })
        .catch((err) => {
          console.log(err);
          this.showOverlay = false;
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style scoped>
.expanded-row {
  border-top: 3px solid #6e6b7b;
  border-left: 3px solid #6e6b7b;
  border-right: 3px solid #6e6b7b;
  transition: border 0.3s ease-in-out;
}
.expanded-row-section2 {
  border-bottom: 3px solid #6e6b7b;
  border-left: 3px solid #6e6b7b;
  border-right: 3px solid #6e6b7b;
  transition: all 0.3s ease-in-out;
}

.custom-button {
  min-width: 150px;
}

.multiple-text-item {
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto;
}

/* .table td, .table th {
    width: 10%;
} */

.my-custom-scrollbar {
  position: relative;
  height: 70vh;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
.header {
  /* position: sticky;
  top: 0;
  z-index: 99; */
}
</style>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>
