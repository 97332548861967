<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="d-flex flex-column align-items-start justify-content-start">
      <div
        class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
        v-if="
          risk.risk &&
          risk.risk != null &&
          risk.risk.name &&
          risk.risk.name != null
        "
      >
        <h6 class="text-primary">Name</h6>
        <div
          class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
        >
          <div
            class="d-flex flex-column align-items-start justify-content-start w-100"
          >
            <p class="font-weight-bold mb-0">
              <span class="align-middle">{{ risk.risk.name }}</span>
            </p>
          </div>
        </div>
      </div>
<!-- 
        <b-card align="center" class="bg-dark-blue h-100 w-100">
          <div class="row">
            <div class="col">
              <b-list-group class="list-group-circle text-left bg-transparent">
                <b-list-group-item class="bg-transparent">
                  <b>Category:</b>
                  <span>
                    <b-badge variant="primary" class="ml-1">{{
                      risk.asset_category ? risk.asset_category.name : ""
                    }}</b-badge></span
                  >
                </b-list-group-item>
                
              </b-list-group>
            </div>
          </div>
        </b-card> -->

      <div
        class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
        v-if="risk.risk_statement && risk.risk_statement != null"
      >
        <h6 class="text-primary">Statement</h6>
        <div
          class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
        >
          <div
            class="d-flex flex-column align-items-start justify-content-start w-100"
          >
            <p class="font-weight-bold mb-0">
              <span class="align-middle">{{ risk.risk_statement }}</span>
            </p>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column align-items-start justify-content-between mb-2 w-100"
        v-if="risk.threat"
      >
        <h6 class="text-primary">Threats</h6>
        <div
          class="rounded border d-flex align-items-center justify-content-between w-100"
        >
          <table role="table" class="table table-sm mb-0">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col"></th>
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col" class="text-center">
                  Value
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr>
                <td role="cell">1</td>
                <td role="cell">
                  {{ risk.threat ? risk.threat.name : "" }}
                </td>
                <td role="cell">
                  <div
                    class="d-flex align-items-center justify-content-center w-100"
                  >
                    <b-badge
                      v-if="
                        risk.threat.threat_value &&
                        risk.threat.threat_value != null &&
                        risk.threat.threat_value != ''
                      "
                      style="width: 120px"
                      :variant="getVariant(risk.threat.threat_value)"
                      >{{ getTextValue(risk.threat.threat_value) }}
                      <b-badge pill class="ml-25" size="sm">{{
                        risk.threat.threat_value
                      }}</b-badge></b-badge
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="d-flex flex-column align-items-start justify-content-between mb-2 w-100"
        v-if="risk.vulnerability && risk.vulnerability !== null"
      >
        <h6 class="text-primary">Vulnerabilities</h6>
        <div
          class="rounded border d-flex align-items-center justify-content-between w-100"
        >
          <table role="table" class="table table-sm mb-0">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col"></th>
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col" class="text-center">
                  Value
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr>
                <td role="cell">1</td>
                <td role="cell">
                  {{ risk.vulnerability ? risk.vulnerability.name : "" }}
                </td>
                <td role="cell">
                  <div
                    class="d-flex align-items-center justify-content-center w-100"
                  >
                    <b-badge
                      v-if="
                        risk.vulnerability.vulnerability_value &&
                        risk.vulnerability.vulnerability_value != null &&
                        risk.vulnerability.vulnerability_value != ''
                      "
                      style="width: 120px"
                      :variant="
                        getVariant(risk.vulnerability.vulnerability_value)
                      "
                      >{{
                        getTextValue(risk.vulnerability.vulnerability_value)
                      }}
                      <b-badge pill class="ml-25" size="sm">{{
                        risk.vulnerability.vulnerability_value
                      }}</b-badge></b-badge
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- <div
        class="d-flex flex-column align-items-start justify-content-start"
        v-if="risk.vulnerability && risk.vulnerability !== null"
      >
        <label class="font-weight-bolder">Vulnerability</label>
        <p class="mb-0">{{ risk.vulnerability.name }}</p>
      </div> -->

      <div
        class="d-flex flex-column align-items-start justify-content-start mt-2"
        v-if="
          risk.asset_risk_controls &&
          risk.asset_risk_controls !== null &&
          Array.isArray(risk.asset_risk_controls) &&
          risk.asset_risk_controls.length > 0
        "
      >
        <label class="font-weight-bolder">Controls</label>
        <ul>
          <template v-for="control in risk.asset_risk_controls">
            <li :key="control.control._id">{{ control.control.name }}</li>
          </template>
        </ul>
      </div>

      <div class="mt-2 table-responsive">
        <table role="table" class="table b-table">
          <thead role="rowgroup" class="thead-dark">
            <tr role="row">
              <th role="columnheader" scope="col" class="text-center header">
                Threat Value
              </th>
              <th role="columnheader" scope="col" class="text-center header">
                Vulnerability Value
              </th>
              <th role="columnheader" scope="col" class="text-center header">
                Likelihood Value
              </th>
            </tr>
          </thead>

          <tbody role="rowgroup">
            <tr class="b-table-has-details cursor-pointer" role="row">
              <td aria-colindex="1" role="cell">
                <div class="d-flex justify-content-center align-items-center">
                  <score-input
                    :id="risk._id"
                    :value="Number(risk.threat_value)"
                    :increment="incrementThreatValue"
                    :decrement="decrementThreatValue"
                  />
                </div>
              </td>
              <td aria-colindex="1" role="cell">
                <div class="d-flex justify-content-center align-items-center">
                  <score-input
                    :id="risk._id"
                    :value="Number(risk.vulnerability_value)"
                    :increment="incrementVulnerabilityValue"
                    :decrement="decrementVulnerabilityValue"
                  />
                </div>
              </td>
              <td aria-colindex="1" role="cell">
                <div class="d-flex justify-content-center align-items-center">
                  <b-form-select
                    style="width: 170px"
                    class="font-weight-bold"
                    size="sm"
                    v-model="risk.likelihood_value"
                    v-on:change="handleLikelihoodValueChange(risk._id)"
                    :options="likelihoodOptions"
                  ></b-form-select>
                </div>
              </td>
            </tr>
            <tr tabindex="-1" role="row" class="b-table-details">
              <td role="cell" colspan="12">
                <div class="card bg-light-secondary">
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-6 d-flex justify-content-center align-items-center"
                      >
                        <progress-bar
                          title="Impact Value"
                          :value="risk.impact_value"
                          :min="1"
                          :max="100"
                        />
                      </div>
                      <div
                        class="col-6 d-flex justify-content-center align-items-center"
                      >
                        <progress-bar
                          title="Risk Value"
                          :value="risk.risk_value"
                          :min="1"
                          :max="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-3 table-responsive">
        <table role="table" class="table b-table">
          <thead role="rowgroup" class="thead-dark">
            <tr role="row">
              <th role="columnheader" scope="col" class="text-center header">
                Residual Threat Value
              </th>
              <th role="columnheader" scope="col" class="text-center header">
                Residual Vulnerability Value
              </th>
              <th role="columnheader" scope="col" class="text-center header">
                Residual Likelihood Value
              </th>
            </tr>
          </thead>

          <tbody role="rowgroup">
            <tr class="b-table-has-details cursor-pointer" role="row">
              <td aria-colindex="1" role="cell">
                <div class="d-flex justify-content-center align-items-center">
                  <score-input
                    :id="risk._id"
                    :value="Number(risk.residual_threat_value)"
                    :increment="incrementResidualThreatValue"
                    :decrement="decrementResidualThreatValue"
                  />
                </div>
              </td>
              <td aria-colindex="1" role="cell">
                <div class="d-flex justify-content-center align-items-center">
                  <score-input
                    :id="risk._id"
                    :value="Number(risk.residual_vulnerability_value)"
                    :increment="incrementResidualVulnerabilityValue"
                    :decrement="decrementResidualVulnerabilityValue"
                  />
                </div>
              </td>
              <td aria-colindex="1" role="cell">
                <div class="d-flex justify-content-center align-items-center">
                  <b-form-select
                    style="width: 170px"
                    class="font-weight-bold"
                    size="sm"
                    v-model="risk.residual_likelihood_value"
                    v-on:change="handleResidualLikelihoodValueChange(risk._id)"
                    :options="likelihoodOptions"
                  ></b-form-select>
                </div>
              </td>
            </tr>
            <tr tabindex="-1" role="row" class="b-table-details">
              <td role="cell" colspan="12">
                <div class="card bg-light-secondary">
                  <div class="card-body">
                    <div class="row mt-3">
                      <div
                        class="col-6 d-flex justify-content-center align-items-center"
                      >
                        <progress-bar
                          title="Residual Impact Value"
                          :value="risk.residual_impact_value"
                          :min="1"
                          :max="100"
                        />
                      </div>
                      <div
                        class="col-6 d-flex justify-content-center align-items-center"
                      >
                        <progress-bar
                          title="Residual Risk Value"
                          :value="risk.residual_risk_value"
                          :min="1"
                          :max="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- <div
        class="mt-3 table-responsive"
        v-if="
          risk.asset_risk_controls &&
          risk.asset_risk_controls !== null &&
          risk.asset_risk_controls.length > 0
        "
      >
        <p class="mb-25 font-weight-bold">Controls</p>
        <table role="table" class="table b-table">
          <thead role="rowgroup" class="thead-dark">
            <tr role="row">
              <th role="columnheader" scope="col" class="text-center header">
                Control No.
              </th>
              <th role="columnheader" scope="col" class="text-center header">
                Name
              </th>
              <th role="columnheader" scope="col" class="text-center header">
                Description
              </th>
            </tr>
          </thead>

          <tbody role="rowgroup">
            <template v-for="(control, ci) in risk.asset_risk_controls">
              <tr :key="control.control_id">
                <td>
                  <span class="text-dark font-weight-bold">{{
                    control.control.control_no
                  }}</span>
                </td>
                <td>
                  <b-form-checkbox
                    :disabled="risk.status !== 1"
                    class="my-1"
                    v-on:change="
                      handleControlsValueChange(
                        $event,
                        risk._id,
                        control.control_id
                      )
                    "
                    v-model="
                      asset.asset_risks[i].asset_risk_controls[ci].exists
                    "
                    ><span class="text-primary font-weight-bold">{{
                      control.control.name
                    }}</span></b-form-checkbox
                  >
                </td>
                <td>
                  <span class="text-dark font-italic">{{
                    control.control.description
                  }}</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div> -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BButton,
  BOverlay,
  BBadge,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import ScoreInput from "@/components/ScoreInput.vue";
import ProgressPercent from "../../../components/ProgressPercent.vue";
import ProgressBar from "../../../components/ProgressBar.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "AssetRisksAnalysisForm",
  components: {
    BFormSelect,
    BCard,
    ScoreInput,
    ProgressPercent,
    ProgressBar,
    BButton,
    VueApexCharts,
    BOverlay,
    BBadge,
    BListGroup,
    BListGroupItem,
  },
  props: {
    showOverlay: {
      type: Boolean,
      required: true,
    },
    risk: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    incrementThreatValue: {
      type: Function,
      required: true,
    },
    decrementThreatValue: {
      type: Function,
      required: true,
    },
    incrementVulnerabilityValue: {
      type: Function,
      required: true,
    },
    decrementVulnerabilityValue: {
      type: Function,
      required: true,
    },
    incrementResidualThreatValue: {
      type: Function,
      required: true,
    },
    decrementResidualThreatValue: {
      type: Function,
      required: true,
    },
    incrementResidualVulnerabilityValue: {
      type: Function,
      required: true,
    },
    decrementResidualVulnerabilityValue: {
      type: Function,
      required: true,
    },
    handleLikelihoodValueChange: {
      type: Function,
      required: true,
    },
    handleResidualLikelihoodValueChange: {
      type: Function,
      required: true,
    },
    handleControlsValueChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      likelihoodOptions: [
        { value: null, text: "Select a value.." },
        { value: 1, text: "Once 5 years" },
        { value: 2, text: "Once 2 years" },
        { value: 3, text: "Once a year" },
        { value: 4, text: "Once in Six Months" },
        { value: 5, text: "Once Month" },
      ],
      series: [90],
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },
          },
        },
        labels: ["Overall Score"],
      },
    };
  },
  methods: {
    percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },

    getVariant(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return "light-success";
        case 2:
          return "light-info";
        case 3:
          return "light-primary";
        case 4:
          return "light-warning";
        case 5:
          return "light-danger";
        default:
          return "light-success";
      }
    },

    getTextValue(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return "Very Low";
        case 2:
          return "Low";
        case 3:
          return "Medium";
        case 4:
          return "High";
        case 5:
          return "Very High";
        default:
          return "Very Low";
      }
    },
  },
};
</script>

<style scoped>
td {
  width: 200px;
}
</style>
