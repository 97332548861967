import { render, staticRenderFns } from "./AssetDetailsPage.vue?vue&type=template&id=21c7907d&scoped=true&"
import script from "./AssetDetailsPage.vue?vue&type=script&lang=js&"
export * from "./AssetDetailsPage.vue?vue&type=script&lang=js&"
import style0 from "./AssetDetailsPage.vue?vue&type=style&index=0&id=21c7907d&scoped=true&lang=css&"
import style1 from "./AssetDetailsPage.vue?vue&type=style&index=1&lang=css&"
import style2 from "./AssetDetailsPage.vue?vue&type=style&index=2&id=21c7907d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c7907d",
  null
  
)

export default component.exports